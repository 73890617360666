import React, { useMemo, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { getActiveLists } from "api";
import { OptizmoExportData, SalesforceData, SalesforceListData } from "pages/md5/export/md5-export.types";

import styles from "pages/md5/export/md5-export.module.scss";

export const Lists: React.FC = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<OptizmoExportData>();

  const [listsOptions, setListsOptions] = useState<SelectOption<number>[]>([]);

  const espData = values.espData as SalesforceData;

  const activeLists = useSubmit(getActiveLists);
  activeLists.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((dataExtension) => ({
      label: dataExtension.ListName,
      value: dataExtension.ID,
    }));
    setListsOptions(options);
  });

  useDidUpdate(
    () => {
      if (espData.businessUnitId) {
        activeLists.submit({ params: { businessUnitId: espData.businessUnitId.toString() } });
      }
    },
    [espData.businessUnitId],
    true,
  );

  const handleNameChange = (fieldKey: string) => async (option: SelectOption | null) => {
    // we only need to set the name - id is set automatically from FormField
    await setFieldValue(`espData.data.${fieldKey}.name`, option ? option.label : "");
    setTimeout(() => setFieldTouched(`espData.data.${fieldKey}.name`, true));
  };

  const filteredFromListsOptions = useMemo(() => {
    const fromListData = espData.data.toList as SalesforceListData;
    if (fromListData?.id) return listsOptions.filter((option) => option.value !== fromListData.id);
    return listsOptions;
  }, [espData.data.toList, listsOptions]);

  const filteredToListsOptions = useMemo(() => {
    const toListData = espData.data.fromList as SalesforceListData;
    if (toListData?.id) return listsOptions.filter((option) => option.value !== toListData.id);
    return listsOptions;
  }, [espData.data.fromList, listsOptions]);

  return (
    <div className={styles.selectGrid} style={{ marginTop: "24px" }}>
      {/*FROM*/}
      <div>
        <div style={{ marginBottom: "24px" }}>
          <div className={styles.row}>
            <div className={styles.step}>6</div>
            <div>
              <Label text="From list" isInputLabel />
              <p className={styles.projectDescription}>Select an initial list</p>
            </div>
          </div>

          <FormField
            type="select"
            name="espData.data.fromList.id"
            onChange={handleNameChange("fromList")}
            options={filteredFromListsOptions}
            isSearchable
            searchPlaceholder="Search lists"
            disabled={!espData?.businessUnitId || activeLists.submitting}
            asyncOptions={{
              loading: activeLists.submitting,
            }}
            isMulti={false}
          />
        </div>
      </div>

      {/*TO*/}
      <div>
        <div style={{ marginBottom: "24px" }}>
          <div className={styles.row}>
            <div className={styles.step}>7</div>
            <div>
              <Label text="To list" isInputLabel />
              <p className={styles.projectDescription}>Select a target list</p>
            </div>
          </div>

          <FormField
            type="select"
            name="espData.data.toList.id"
            onChange={handleNameChange("toList")}
            options={filteredToListsOptions}
            isSearchable
            searchPlaceholder="Search lists"
            disabled={!espData?.businessUnitId || activeLists.submitting}
            asyncOptions={{
              loading: activeLists.submitting,
            }}
            isMulti={false}
          />
        </div>
      </div>
    </div>
  );
};
