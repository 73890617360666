import React from "react";
import cn from "classnames";
import { Loader } from "@epcnetwork/core-ui-kit";

import { Badge, BadgeProps } from "components";
import { AllJobsStatues } from "models";
import { Check, Circle, Failed } from "assets";

import styles from "./job-item-status.module.scss";

interface Props {
  status: AllJobsStatues;
  successCount?: string | number;
  valuesCount?: number;
}

export const JobItemStatus: React.FC<Props> = ({ status, successCount, valuesCount }) => {
  const statusDetails: Record<AllJobsStatues, { text: string; icon: React.ReactNode; variant: BadgeProps["variant"] }> =
    {
      created: { text: "Created", icon: <Circle />, variant: "default" },
      inProgress: {
        text: "In progress",
        icon: <Loader type="clip-loader" loaderClassName={styles.loader} />,
        variant: "default",
      },
      finished: { text: "Finished", icon: <Check />, variant: "finished" },
      success: { text: "Success", icon: <Check />, variant: "success" },
      aborting: { text: "Aborting", icon: <Circle />, variant: "default" },
      aborted: { text: "Aborted", icon: <Circle />, variant: "default" },
      failed: { text: "Failed", icon: <Failed />, variant: "error" },
      error: { text: "Error", icon: <Failed />, variant: "error" },
    };

  const showNumericValues = successCount !== undefined;

  return (
    <Badge className={cn(styles.badge)} variant={statusDetails[status]?.variant}>
      {statusDetails[status]?.icon}{" "}
      <span>
        {statusDetails[status]?.text}{" "}
        {showNumericValues && (
          <span>
            ({+successCount}/{valuesCount})
          </span>
        )}
      </span>
    </Badge>
  );
};
