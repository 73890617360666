import React, { useCallback, useState } from "react";
import {
  Button,
  DropAcceptedFunc,
  FileItem,
  FileUpload,
  Label,
  notification,
  Nullable,
  useFileQueue,
} from "@epcnetwork/core-ui-kit";
import classNames from "classnames";
import { useDidUpdate } from "@better-hooks/lifecycle";

import {
  getInitialData,
  getSupportedFormats,
} from "pages/suppression/create/suppress-from-file/suppress-from-file.utils";
import { Md5ImportValues } from "../md5-import-values/md5-import-values";
import { Md5FileInfo, Md5UploadData } from "./md5-file-import.types";
import { Md5FileItem } from "../md5-import-values/md5-import-values.types";
import { uploadMd5 } from "api";
import { csvFormat } from "constants/file.constants";

import styles from "pages/md5/import/md5-import.module.scss";
import offerStyles from "../offer-selector/offer-selector.module.scss";

interface Props {
  offerId: number;
}

export const Md5FileImport: React.FC<Props> = ({ offerId }) => {
  const [editedFile, setEditedFile] = useState<Nullable<Md5FileItem>>(null);

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
    hasAllSuccessFiles,
  } = useFileQueue<Md5FileItem, Md5UploadData>("Md5Upload", uploadMd5);

  const handleDropAccept: DropAcceptedFunc = useCallback(
    async (acceptedFiles) => {
      addFiles(createInitialFile(acceptedFiles[0], { data: getInitialData(acceptedFiles[0]) }));
    },
    [addFiles, createInitialFile],
  );

  const handleSubmit = async () => {
    if (!offerId) {
      notification.error("Offer is required", "You need to select an offer first");
      return;
    }

    const data = files.map((file) => {
      const formData = new FormData();

      const { fileName, emailIndex, hasHeaders } = file.data;
      const suppressionData: Md5FileInfo = {
        emailIndex,
        hasHeaders,
        offerId: 1,
      };

      formData.append("form", JSON.stringify(suppressionData));
      formData.append("file", file.originalFile, fileName);

      return {
        id: file.id,
        data: formData,
      };
    });

    await submitOneByOne(data);
  };

  useDidUpdate(() => {
    const haveError = Boolean(files[0]?.error);

    if (isEntityFinished && !haveError) {
      notification.success("Success!", "Emails were added to queue.");

      removeFiles(files[0].id);
      clearEntity();

      notification.success("Success!", `MD5 emails successfully imported.`);
    }

    if (isEntityFinished && haveError) {
      const error = files[0]?.error;
      notification.error("Error!", `Could not add emails to queue. ${error}`);
    }
  }, [isEntityFinished]);

  const closeModal = () => setEditedFile(null);

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: Md5FileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleItemConfiguration = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const getAcceptedFilesText = (formats: string[]): string => `Accepted ${formats.join(", ")} files`;

  const supportedFormats = [csvFormat].flat();

  return (
    <div className={styles.card}>
      <div className={offerStyles.row}>
        <div className={offerStyles.step}>4</div>
        <div>
          <Label text="Upload" isInputLabel />
          <p className={offerStyles.projectDescription}>Upload a CSV file with MD5</p>
        </div>
      </div>

      <div className={styles.form}>
        <FileUpload
          className={classNames(styles.dropZone, { [styles.uploadZone]: files.length })}
          uploadedFilesLength={files.length}
          subtitle={`${getAcceptedFilesText(getSupportedFormats(supportedFormats))} - files with max 10 000 lines are accepted.`}
          accept={supportedFormats}
          onDropAccepted={handleDropAccept}
          exceedFilesOption="splice-with-error"
          disabled={!isEntityInConfiguration}
          preventDropOnDocument
          multiple={false}
          // todo: add max size?
          // maxSize={300000}
        />
        <div className={styles.fileList}>
          {files.map(({ id, originalFile, data, ...rest }) => {
            const showFileContent = data.emailIndex >= 0;

            return (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleItemConfiguration}
                onEditValuesClick={handleItemConfiguration}
              >
                {showFileContent && (
                  <div className={styles.column}>
                    {data.emailIndex >= 0 && (
                      <div className={styles.additionalInfo}>
                        <span>Selected MD5 column:</span> {data.emailIndex + 1}
                      </div>
                    )}
                  </div>
                )}
              </FileItem>
            );
          })}
        </div>
        {editedFile && (
          <Md5ImportValues file={editedFile} onCloseClick={closeModal} onSubmitClick={handleModalSubmit} />
        )}

        <div className={styles.buttons}>
          {!isEntityFinished && (
            <Button
              onClick={handleSubmit}
              disabled={!hasAllConfigured || !files.length || !offerId}
              loading={!isEntityInConfiguration}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
