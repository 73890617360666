import React from "react";
import { Form, Formik } from "formik";
import { useSubmit } from "@hyper-fetch/react";
import { notification } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";

import { Container } from "components";
import { OptizmoSelector } from "./optizmo-selector/optizmo-selector";
import { EspSelector } from "./esp-selector/esp-selector";
import { OptizmoExportData } from "./md5-export.types";
import { initialValues, validationSchema } from "./md5-export.constants";
import { createOfferMd5s, MatchMd5sData } from "api";
import { MD5_JOBS_PAGE } from "constants/routes.constants";
import { OptizmoExportContextProvider } from "./md5-export.context";

import styles from "./md5-export.module.scss";

export const Md5ExportPage: React.FC = () => {
  const navigate = useNavigate();

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(createOfferMd5s);
  onSubmitSuccess(() => {
    notification.success("Success!", "Export created.");
    navigate(MD5_JOBS_PAGE.path);
  });
  onSubmitError(() => {
    notification.error("Error!", "Could not create an export.");
  });

  const handleSubmit = async (values: OptizmoExportData) => {
    const { offerId, espData } = values;
    if ("fromListType" in espData) delete espData.fromListType;
    if ("toListType" in espData) delete espData.toListType;

    if (espData.esp === "iterable") {
      const { accountId, ...rest } = espData;
      const data: MatchMd5sData = { offerId, espData: rest };

      await submit({ data });
    }

    if (espData.esp === "salesforce") {
      const data: MatchMd5sData = { offerId, espData };
      await submit({ data });
    }
  };

  return (
    <Container>
      <OptizmoExportContextProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className={styles.wrapper}>
                  <OptizmoSelector />
                  <EspSelector />
                </div>
              </Form>
            );
          }}
        </Formik>
      </OptizmoExportContextProvider>
    </Container>
  );
};
